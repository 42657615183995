import { RC_DURATION } from '@lambda/reebelo';
import auWarrantyPricing from './pricing/AU.json';
import usWarrantyPricing from './pricing/US.json';
import us13WarrantyPricing from './pricing/US_13.json';
import dev13WarrantyPricing from './pricing/DEV_13.json';
import nzWarrantyPricing from './pricing/NZ.json';
import sgWarrantyPricing from './pricing/SG.json';
import devWarrantyPricing from './pricing/DEV.json';

export type WarrantyItemPrice = {
  from: number;
  to: number;
  price: number;
  variantId: number;
};

const generateWarrantyPricing = () => {
  if (process.env.NEXT_PUBLIC_STORE === 'reebelo-au')
    return { 24: auWarrantyPricing };

  if (process.env.NEXT_PUBLIC_STORE === 'reebelo-us')
    return { 13: us13WarrantyPricing, 24: usWarrantyPricing };

  if (process.env.NEXT_PUBLIC_STORE === 'reebelo-nz')
    return { 24: nzWarrantyPricing };

  if (process.env.NEXT_PUBLIC_STORE === 'quista')
    return { 24: sgWarrantyPricing };

  if (process.env.NEXT_PUBLIC_STORE === 'reebelo-dev')
    return { 24: devWarrantyPricing, 13: dev13WarrantyPricing };

  return {};
};

const warrantyPricing = generateWarrantyPricing();

export default warrantyPricing as Record<
  RC_DURATION,
  Record<string, Array<WarrantyItemPrice>>
>;
